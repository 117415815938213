exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-style-guide-tsx": () => import("./../../../src/pages/style-guide.tsx" /* webpackChunkName: "component---src-pages-style-guide-tsx" */),
  "component---src-pages-whats-on-tsx": () => import("./../../../src/pages/whats-on.tsx" /* webpackChunkName: "component---src-pages-whats-on-tsx" */),
  "component---src-templates-custom-page-template-tsx": () => import("./../../../src/templates/customPageTemplate.tsx" /* webpackChunkName: "component---src-templates-custom-page-template-tsx" */),
  "component---src-templates-event-category-template-tsx": () => import("./../../../src/templates/eventCategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-event-category-template-tsx" */),
  "component---src-templates-event-template-tsx": () => import("./../../../src/templates/eventTemplate.tsx" /* webpackChunkName: "component---src-templates-event-template-tsx" */),
  "component---src-templates-event-type-template-tsx": () => import("./../../../src/templates/eventTypeTemplate.tsx" /* webpackChunkName: "component---src-templates-event-type-template-tsx" */),
  "component---src-templates-interactive-page-template-tsx": () => import("./../../../src/templates/interactivePageTemplate.tsx" /* webpackChunkName: "component---src-templates-interactive-page-template-tsx" */),
  "component---src-templates-page-category-template-tsx": () => import("./../../../src/templates/pageCategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-page-category-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/pageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */)
}

